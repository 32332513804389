import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import Vote from "../components/vote/Vote";
import config from "../helpers/config";
import LeftMenuPanel from "../components/LeftMenuPanel";

const VoteScreen = () => {
    const params = useParams();

    //change title
    useEffect(() => {
        document.title = config.documentTitle + " - Voting";

        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="default-nav-mt">
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={4} className="mb-4">
                        <LeftMenuPanel activeIndex={99} />
                    </Col>
                    <Col sm={12} md={12} lg={8}>
                        <Vote id={params.id} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default VoteScreen;