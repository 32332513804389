import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { read, voting } from '../../services/vote';
import swal from 'sweetalert';
import moment from 'moment';
import { Title } from '../../screens/DPTCheckScreen';
import { Candidate } from './Candidate';

const Vote = (props) => {
    const id = props.id;

    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const [data, setDate] = useState(null);
    const [votedCandidates, setVotedCandidates] = useState([]);
    const [candidates, setCandidates] = useState([]);

    const [triggerReloadTimestamp, setTriggerReloadTimestamp] = useState(null);

    useEffect(() => {
        async function getDetail(id) {
            const res = await read(auth.token, id);
            if (res.error == null) {
                setDate(res.data);
                setCandidates(res.data.candidates);
                setVotedCandidates(res.data.voted_candidates);
            } else {
                swal("Gagal", res.error, "error");

                navigate('/');
            }
        }

        getDetail(id);
    }, [auth, id, navigate, triggerReloadTimestamp]);

    // const viewDetail = (id) => {
    //     dispatch(showUserDetail(id));
    // }

    const votingUser = async (user) => {
        //show confirm
        var confirm = await swal({
            title: "Konfirmasi",
            text: "Apakah anda yakin ingin memilih " + (user.first_name + ' ' + user.last_name) + "?\n\nAnda tidak dapat mengubah pilihan setelah ini.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirm) {
            const res = await voting(auth.token, id, user.id);
            if (res.error === null) {
                setTriggerReloadTimestamp(new Date().getTime());

                const confirm = await swal({
                    title: "Terimakasih Telah Berpartisipasi",
                    text: "Pilihan Anda sukses tersimpan",
                    icon: "success",
                    button: "OK",
                });

                if (confirm) {
                    navigate('/check-dpt');
                }
            } else {
                swal("Gagal", res.error, "error");
            }
        }
    }

    const candidatesNormalized = useMemo(() => {
        console.log('debug', candidates, votedCandidates)

        // add flag is_voted if user already voted
        return candidates.map(candidate => {
            const isVoted = votedCandidates.find(votedCandidate => votedCandidate.user_id === candidate.user_id) !== undefined;
            return {
                ...candidate,
                is_voted: isVoted
            }
        });
    }, [candidates, votedCandidates])

    if (!data) return null

    return (
        <>
            <div className='box p-4'>
                <div className='text-center'>
                    <h3>{data.name}</h3>
                    <p>{data.description}</p>
                </div>
                <div className='mb-2' dangerouslySetInnerHTML={{ __html: data.body }} />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                }}>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                    {
                        moment(data.start_datetime).format('HH:mm, DD MMMM YYYY') + ' - ' + moment(data.end_datetime).format('HH:mm, DD MMMM YYYY')
                    }
                </div>
                <div className='mt-4'>
                    <div>
                        Maksimum Pilihan: <b>{data.max_choice}</b>
                        <br />
                        Kuota Tersisa: <b>{data.max_choice - votedCandidates.length}</b>
                    </div>
                </div>
            </div>
            <div>

                <Title title="Kandidat" />

                {
                    candidatesNormalized.map((candidate, index) => (
                        <Candidate candidate={candidate} key={index} onClick={() => candidate.is_voted ? null : votingUser(candidate)} />
                    ))
                }
            </div>
        </>
    );
}

export default Vote;