export const read = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}vote/read`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            id: id,
        })
    });
    const json = await response.json();
    return json;
}

export const readAll = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}vote/readAll`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    const json = await response.json();
    return json;
}

export const isOnWhitelist = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}vote/isOnWhitelist`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });
    const json = await response.json();
    return json;
}

export const voting = async (token, vote_id, vote_candidate_id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}vote/voting`, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            vote_id: vote_id,
            vote_candidate_id: vote_candidate_id,
        })
    });
    const json = await response.json();
    return json;
}