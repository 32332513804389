export function Candidate({ candidate, onClick }) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid #ccc',
            marginBottom: 10,
            background: '#f9f9f9',
            padding: 10
        }}>
            <div style={{
                width: '400px'
            }}>
                <b>{candidate.first_name + ' ' + (candidate.last_name ?? "")}</b>
                <div style={{
                    color: '#575757',
                    fontSize: 12
                }}>
                    Nomor Anggota: {candidate.iai_id === null || candidate.iai_id === '' ? '-' : candidate.iai_id}
                </div>
                <div className="mt-2">
                    {candidate.management_track_record !== null && candidate.management_track_record !== '' ?
                        <div dangerouslySetInnerHTML={{ __html: candidate.management_track_record }}></div>
                        : null}
                </div>
            </div>
            <div>
                <button disabled={candidate.is_voted} onClick={onClick} className='btn btn-sm btn-default'>Pilih</button>
            </div>
        </div>
    )
}